
type Options = {
  weekday?: "long" | "short" | "narrow",
  year?: "2-digit" | "numeric",
  month?: "2-digit" | "numeric" | "long" | "short" | "narrow",
  day?: "2-digit" | "numeric",
  hour?: "2-digit" | "numeric",
  minute?: "2-digit" | "numeric",
  second?: "2-digit" | "numeric"
}

class MyDate {
  originaleStr: string
  date: Date | null

  constructor(str?: string | null | undefined | Date) {
    this.originaleStr = ""
    if (str === null) {
      this.date = null
    } else if (str === undefined) {
      this.date = new Date()
    } else if (typeof str === "string") {
      this.date = new Date(str)
      this.originaleStr = str
    } else {
      this.date = new Date(str)
    }
  }

  formatOptions(letter: string): Options {
    let option = {}
    switch (letter) {
      case "L":
        option = { weekday: "long" }
        break
      case "l":
        option = { weekday: "short" }
        break
      case "y":
        option = { year: "2-digit" }
        break
      case "Y":
        option = { year: "numeric" }
        break
      case "m":
        option = { month: "2-digit" }
        break
      case "F":
        option = { month: "long" }
        break
      case "d":
        option = { day: "2-digit" }
        break
      case "H":
        option = { hour: "2-digit" }
        break
      case "i":
        option = { minute: "2-digit" }
        break
      case "s":
        option = { second: "2-digit" }
    }
    return option
  }

  validAndCompleteFormat(str: string, format: string): string {
    if (format === "Y" && str.length < 4) {
      str = str.padStart(4, "0")
    }
    if (format === "i" && str.length < 2) {
      str = str.padStart(2, "0")
    }
    return str
  }

  convert(letter: string, language: string = "fr"): string {
    const cases = ["d", "m", "y", "Y", "l", "L", "F", "H", "i", "s"]
    if (cases.indexOf(letter) === -1) {
      return letter
    }
    let str = this.date ? this.date.toLocaleString(language === "fr" ? "fr-FR" : "en-US", this.formatOptions(letter)) : ""
    if (letter === "H") { str = str.replace(" h", "") }
    return this.validAndCompleteFormat(str, letter)
  }

  format(format: string, language: string = "fr"): string {
    const letters = format.split("")
    return letters.map(letter => this.convert(letter, language)).join("")
  }

  getDate(): Date {
    return this.date ? this.date : new Date()
  }
}

export default MyDate