import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { ExpenseReport, ExpenseReportForm } from "./types"



function getFromCurrentUser(params?: any): Promise<ExpenseReport[]> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ expense_reports: ExpenseReport[] }>>(`mission/expense-reports`, params)
      .then(r => resolve(r.data.data.expense_reports))
      .catch(err => reject(err))
  })
}

function postCreate(id: string, data: ExpenseReportForm): Promise<boolean> {
  return new Promise((resolve, reject) => {
    axios.post<HiveApiV2Response>(`mission/assignment-options/${id}/expense-reports`, data)
      .then(r => resolve(true))
      .catch(err => reject(err))
  })
}

const expenseReport = {
  getFromCurrentUser,
  postCreate
}

export default expenseReport