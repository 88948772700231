import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { AdminValidation, Progress, ValidationState } from "./types"


function getFromCurrentUser(states?: ValidationState[]): Promise<AdminValidation[]> {

  var params = states ? { params: {'filters' : { 
      "state": states,
      "admin_control": {
        "required": 1
      }
   }}} : {}

  return new Promise((resolve, reject) => {
    
    axios.get<HiveApiV2Response<{ admin_validations: AdminValidation[] }>>(`recruitment/admin-validations`, params)
    .then( async (r) => {
      resolve(r.data.data.admin_validations)      
    })
      .catch(err => reject(err))
  })
}

function getProgressFromCurrentUser(): Promise<Progress> {

  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ progress: Progress }>>(`recruitment/admin-validations/progress`)
      .then(async (r) => {
      resolve(r.data.data.progress)     
    })
      .catch(err => reject(err))
  })
}

const adminValidation = {
  getFromCurrentUser,
  getProgressFromCurrentUser
}

export default adminValidation