import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store"

export type FileUpload = {
  uploadId: string
  fileId: string
  newFileId?: string
  label: string
  fileName: string
  fileSize: string
  progress: number
  uploadState: string
  notified: boolean
}

export type Upload = {
  id: string,
  files: FileUpload[]
}

type stateUpload = { uploads: Upload[] }

const initialState: stateUpload = {
  uploads: []
}

export const fileUploadSlice = createSlice({
  name: "fileUpload",
  initialState,
  reducers: {
    ADD_FILES: (state: stateUpload, action: PayloadAction<Upload>) => {
      const index = state.uploads.findIndex(upload => upload.id === action.payload.id)
      if (index !== -1) {
        state.uploads[index].files = [...state.uploads[index].files, ...action.payload.files]
      } else {
        state.uploads = [...state.uploads, action.payload]
      }
    },
    REMOVE_ALL_FILES: (state: stateUpload, action: PayloadAction<{id: string}>) => {
      const index = state.uploads.findIndex(upload => upload.id === action.payload.id)
      state.uploads[index] && ( state.uploads[index].files = [] )
    },
    DELETE_NOTIFICATION: (state: stateUpload, action: PayloadAction<{ uploadId: string; fileId: string } | null>) => {
      if (action.payload) {
        state.uploads = state.uploads.map(upload => {
          if (upload.id === action.payload?.uploadId) {
            return { ...upload, files: upload.files.map(file => file.fileId === action.payload?.fileId ? { ...file, notified: false } : file) }
          } else {
            return upload
          }
        })
      } else {
        state.uploads = state.uploads.map(upload => ({ ...upload, files: upload.files.map(file => ({ ...file, notified: false })) }))
      }
    },
    UPDATE_PROGRESS: (state: stateUpload, action: PayloadAction<{ uploadId: string; fileId: string; progress: number }>) => {
      state.uploads = state.uploads.map(upload => {
        if (upload.id === action.payload.uploadId) {
          return { ...upload, files: upload.files.map(file => file.fileId === action.payload.fileId ? { ...file, progress: action.payload.progress } : file) }
        } else {
          return upload
        }
      })
    },
    UPDATE_UPLOAD_STATE: (state: stateUpload, action: PayloadAction<{ uploadId: string; fileId: string; uploadState: string; newFileId?: string }>) => {
      state.uploads = state.uploads.map(upload => {
        if (upload.id === action.payload.uploadId) {
          return {
            ...upload, files: upload.files.map(file => {
              if (!action.payload.newFileId) {
                return file.fileId === action.payload.fileId ? { ...file, uploadState: action.payload.uploadState } : file
              } else {
                return file.fileId === action.payload.fileId ? { ...file, uploadState: action.payload.uploadState, fileId: action.payload.newFileId } : file
              }
            })
          }
        } else {
          return upload
        }
      })
    }
  }
})

export const { ADD_FILES, DELETE_NOTIFICATION, UPDATE_PROGRESS, UPDATE_UPLOAD_STATE, REMOVE_ALL_FILES } = fileUploadSlice.actions

export const selectUploadById = (id: string) => (state: RootState) => {
  let selectedUpload = state.uploads.uploads.find(upload => upload.id === id)
  return (selectedUpload ? selectedUpload.files : []) as FileUpload[]
}

export const selectFileUploadNotifications = (state: RootState) => {
  if (state.uploads) {
    return state.uploads.uploads.reduce<FileUpload[]>((notified_files, upload) => {
      const files = upload.files.filter(file => file.notified).map(item => ({ ...item, uploadId: upload.id }))
      return notified_files.concat(files)
    }, [])
  } else {
    return []
  }
}

export const selectFileUploaded = (state: RootState) => selectFileUploadNotifications(state).filter(element => element.uploadState === "done").length

export default fileUploadSlice.reducer
