import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'
import { UserType } from './userSlice'
import { ProfilQualification } from '../../services/queries/core/profilQualification/types'

const setProfilQuafications = (state: UserType, action: PayloadAction<ProfilQualification[]>) => {
  const profilQualifications = action.payload
  state.profilQualifications = profilQualifications
}

const editProfilQuafication = (state: UserType, action: PayloadAction<ProfilQualification>) => {
  if (state.profilQualifications?.find((pq) => pq.type === action.payload.type)) {
    state.profilQualifications = state.profilQualifications?.map((pq) =>
      pq.type === action.payload.type ? action.payload : pq
    )
  } else {
    state.profilQualifications = [...state.profilQualifications, action.payload]
  }
}

const functions = {
  SET_PROFIL_QUALIFICATIONS: setProfilQuafications,
  EDIT_PROFIL_QUALIFICATION: editProfilQuafication,
}

export default functions
