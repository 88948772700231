import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { IconPack, library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { selectAccessToken } from "./store/auth/authSlice";
import { useAppSelector } from "./store/hook";
import UploadNotification from "./components/UploadNotification/UploadNotification";
import { Toaster } from "react-hot-toast";
import PageLoader from "./components/PageLoader/PageLoader";
import ReactTooltip from "react-tooltip";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";

library.add(fas as IconPack)
library.add(far as IconPack)
library.add(fab as IconPack)

const AuthenticatedRouter = React.lazy(() => import("./services/router/AuthenticatedRouter"))
const UnauthenticatedRouter = React.lazy(() => import("./services/router/UnauthenticatedRouter"))
const MaintenanceRouter = React.lazy(() => import("./services/router/MaintenanceRouter"))

const maintenance = process.env.REACT_APP_MAINTENANCE

const App: React.FC = () => {

  const accessToken = useAppSelector(selectAccessToken)
  const [isPublicPath, setIsPublicPath] = useState<boolean>(true)
  const { i18n } = useTranslation()

  useEffect(() => {
    const href = window.location.href
    setIsPublicPath(/\/public\//.test(href) || /\/welcome$/.test(href) || /\/security/.test(href))
  }, [window.location.href]) // eslint-disable-line react-hooks/exhaustive-deps
  

  useEffect(() => {
    i18n.changeLanguage(localStorage['locale'] ? localStorage['locale'] : "fr")
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Suspense fallback={<PageLoader />}>
      <ReactTooltip id='global-tooltip' />
      <BrowserRouter>
      {maintenance && maintenance === "TRUE"
        ? <MaintenanceRouter />
        : accessToken && !isPublicPath ? <AuthenticatedRouter /> : <UnauthenticatedRouter />
      }
        
        <Toaster position="top-right" toastOptions={{ className: "toaster", duration: 4000 }} />
        <UploadNotification />
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
