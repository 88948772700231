import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { StationMonitoring } from "./types"


function getAllFromField(id: number): Promise<StationMonitoring[]> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ stations: StationMonitoring[] }>>(`monitoring/fields/${id}/stations`)
      .then(r => resolve(r.data.data.stations))
      .catch(err => reject(err))
  })
}

function get(id: number): Promise<StationMonitoring> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ station: StationMonitoring }>>(`monitoring/stations/${id}`)
      .then(r => resolve(r.data.data.station))
      .catch(err => reject(err))
  })
}

const station = {
  getAllFromField, 
  get
}

export default station 