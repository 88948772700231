import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { Mission } from "./types"


function getByAssignmentOption(params: any): Promise<Mission[]> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ missions: Mission[] }>>(`mission/missions`, params)
      .then(r => { resolve(r.data.data.missions) })
      .catch(err => reject(err))
  })
}

function get(id: number): Promise<Mission> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ mission: Mission }>>(`mission/missions/${id}`)
      .then(r => { resolve(r.data.data.mission) })
      .catch(err => reject(err))
  })
}

function changeState(id: number, action: "accept" | "refuse"): Promise<Mission> {
  return new Promise((resolve, reject) => {
    axios.put<HiveApiV2Response<{ mission: Mission }>>(`mission/missions/${id}/${action}`)
      .then(r => { resolve(r.data.data.mission) })
      .catch(err => reject(err))
  })
}

function getByListAssignmentOption(ids: number[]): Promise<Mission[]> {
  const params = {
    params: {
      'filters': {
        'assignment_option': { 'ids': ids.join(';') },
        'state': ['VALID', 'ACCEPTED', 'REFUSED']
      }
    }
  }
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ missions: Mission[] }>>(`mission/missions`, params)
      .then(r => { resolve(r.data.data.missions) })
      .catch(err => reject(err))
  })
}

function getFinalPdf(id: number): Promise<string> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ base64: string }>>(`mission/missions/${id}/download-final-pdf`)
      .then(r => { resolve(r.data.data.base64) })
      .catch(err => reject(err))
  })
}

const mission = {
  getByAssignmentOption,
  get,
  changeState,
  getByListAssignmentOption,
  getFinalPdf
}

export default mission