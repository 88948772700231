import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { Expense } from "./types"



export function getFromCurrentUser(params?: any): Promise<Expense[]> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ expenses: Expense[] }>>(`mission/expenses`, params)
      .then(r => resolve(r.data.data.expenses))
      .catch(err => reject(err))
  })
}

const expense = {
  getFromCurrentUser
}

export default expense