import axios from 'axios'
import { HiveApiV2Response } from '../../types'
import { ProfilQualification, ProfilQualificationPost } from './types'

function get(): Promise<ProfilQualification[]> {
  return new Promise((resolve, reject) => {
    axios
      .get<HiveApiV2Response<{ profil_qualifications: ProfilQualification[] }>>(`/core/profil-qualifications/self`)
      .then((r) => resolve(r.data.data.profil_qualifications))
      .catch((err) => reject(err))
  })
}

function post(params: ProfilQualificationPost): Promise<ProfilQualification> {
  return new Promise((resolve, reject) => {
    axios
      .post<HiveApiV2Response<{ profil_qualification: ProfilQualification }>>(`/core/profil-qualifications`, params)
      .then((r) => resolve(r.data.data.profil_qualification))
      .catch((err) => reject(err))
  })
}

const profilQueries = {
  get,
  post,
}

export default profilQueries
