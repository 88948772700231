import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { TrainingForm } from "./types"


export function get(id: number): Promise<TrainingForm> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ training_form: TrainingForm }>>(`recruitment/training-forms/${id}`)
      .then(r => resolve(r.data.data.training_form))
      .catch(err => reject(err))
  })
}

export function edit(trainingForm: TrainingForm): Promise<any> {
  return new Promise((resolve, reject) => {
    axios.put<HiveApiV2Response<{ training_form: any }>>(`recruitment/training-forms/${trainingForm.id}`, trainingForm)
      .then(r => resolve(r.data.data.training_form))
      .catch(err => reject(err))
  })
}

const requests = {
  get,
  edit
}

export default requests