import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { ActionType, AuthCode } from "./types"

function create(action: ActionType, target: string): Promise<AuthCode> {
  const params = { action, target }
  return new Promise((resolve, reject) => {
    axios.post<HiveApiV2Response<{ auth_code: AuthCode }>>("/core/auth-codes", params)
      .then(r => resolve(r.data.data.auth_code))
      .catch(err => reject(err.data))
  })
}

function confirm(token: string, code: string): Promise<boolean> {
  const params = { token, code }
  return new Promise((resolve, reject) => {
    axios.put<HiveApiV2Response>("/core/auth-codes/confirm", params)
      .then(r => resolve(true))
      .catch(err => reject(err.data))
  })
}

const requests = {
  create,
  confirm
}

export default requests;