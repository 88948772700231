import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { AssignmentOption } from "./types"


function getFromField(id: number): Promise<AssignmentOption> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ assignment_options: AssignmentOption }>>(`recruitment/fields/${id}/assignment-options`)
      .then(r => resolve(r.data.data.assignment_options))
      .catch(err => reject(err))
  })
}

function get(id: number): Promise<AssignmentOption> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ assignment_option: AssignmentOption }>>(`recruitment/assignment-options/${id}`)
      .then(r => {resolve(r.data.data.assignment_option)})
      .catch(err => reject(err))
  })
}

function getFromCurrentUser(params?: any): Promise<AssignmentOption[]> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ assignment_options: AssignmentOption[] }>>(`recruitment/assignment-options`, params)
    .then( async (r) => {
      resolve(r.data.data.assignment_options)
    })
      .catch(err => reject(err))
  })
}

function post(id_field: number, origin_id?: number): Promise<AssignmentOption> {
  return new Promise((resolve, reject) => {
    axios.post<HiveApiV2Response<{ assignment_option: AssignmentOption }>>(`recruitment/fields/${id_field}/assignment-options/current`, origin_id && {origin_id})
      .then( async (r) => {
        resolve(r.data.data.assignment_option)
      })
      .catch(err => reject(err))
  })
}

function refuse(id: string): Promise<AssignmentOption> {
  return new Promise((resolve, reject) => {
    axios.put<HiveApiV2Response<{ assignment_option: AssignmentOption }>>(`recruitment/assignment-options/${id}/refuse`)
      .then( async (r) => {
        resolve(r.data.data.assignment_option)
      })
      .catch(err => reject(err))
  })
}

const assignmentOption = {
  getFromField,
  getFromCurrentUser,
  get,
  post,
  refuse
}

export default assignmentOption