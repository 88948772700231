import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { Contrat } from "./types"



function getByAssignmentOption(id: number): Promise<Contrat[]> {
  const params = {
    params: {
      'filters': {
        'missions': {
          'assignment_option': { 'id': id }
        },
        'state': ['CREATED', 'SIGNED']
      }
    }
  }
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ contracts: Contrat[] }>>(`mission/contracts`, params)
      .then(r => { resolve(r.data.data.contracts) })
      .catch(err => reject(err))
  })
}

function getFromCurrentUser(params: any = {
  params: {
    'filters': {
      'state': ['CREATED', 'SIGNED', 'EXPIRED']
    }
  }
}): Promise<Contrat[]> {

  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ contracts: Contrat[] }>>(`mission/contracts`, params)
      .then(r => { resolve(r.data.data.contracts) })
      .catch(err => reject(err))
  })
}

function getFinalPdf(id: number): Promise<string> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ base64: string }>>(`mission/contracts/${id}/download-final-pdf`)
      .then(r => { resolve(r.data.data.base64) })
      .catch(err => reject(err))
  })
}

function setInsertions(id: number, tab_insertion: Array<string>): Promise<Contrat> {
  return new Promise((resolve, reject) => {
    axios.put<HiveApiV2Response<{ contract: Contrat }>>(`mission/contracts/${id}/user`, {
      insertion_state: tab_insertion.join(';')
    })
      .then(r => { resolve(r.data.data.contract) })
      .catch(err => reject(err))
  })
}


const contract = {
  getByAssignmentOption,
  getFromCurrentUser,
  getFinalPdf,
  setInsertions
}

export default contract