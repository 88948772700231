import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { User, UserRegister } from "./types"

function current(): Promise<User> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ user: User }>>("/core/users/current")
      .then(r => resolve(r.data.data.user))
      .catch(err => reject(err))
  })
}

function post(user: UserRegister, recaptcha: string): Promise<boolean> {

  (user.profil?.firstname) && (user.profil.firstname = user.profil?.firstname?.trim());
  (user.profil?.lastname) && (user.profil.lastname = user.profil?.lastname?.trim());

  return new Promise((resolve, reject) => {
    axios.post<HiveApiV2Response>(`/public/core/users`, { ...user, recaptcha })
      .then(r => resolve(true))
      .catch(err => reject(err))
  })
}

function sendRegisterConfirmationMail(username: string, password: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    axios.post<HiveApiV2Response>(`public/core/users/send-register-confirmation-mail`, { password, username })
      .then(r => resolve(true))
      .catch(err => reject(err))
  })
}

function enableByToken(userToken: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    axios.put<HiveApiV2Response>(`/public/core/users/${userToken}/enable`)
      .then(() => resolve(true))
      .catch(err => reject(err))
  })
}

function getCurrentByToken(userToken: string): Promise<User> {
  const params = { params: { "X-PROFIL-TOKEN": userToken } }
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ user: User }>>("/token-security/core/users/current", params)
      .then(r => resolve(r.data.data.user))
      .catch(err => reject(err))
  })
}

function emailExist(email: string): Promise<boolean> {
  const params = { params: { email } }
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ exist: boolean }>>("/public/core/users/email-exist", params)
      .then(r => { resolve(r.data.data.exist) })
      .catch(err => reject(err))
  })
}

function getForgottenEmail(
  firstname: string,
  lastname: string,
  mobile: string,
  cp: string
): Promise<string> {
  const params = { params: { firstname, lastname, mobile, postal_code: cp } }
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ user: { email: string } }>>("/public/core/users/forgotten-email", params)
      .then(r => resolve(r.data.data.user.email))
      .catch(err => reject(err))
  })
}

function putEmail(email: string, token: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    axios.put<HiveApiV2Response>(`/core/users/email`, { email, token })
      .then(r => resolve(true))
      .catch(err => reject(err))
  })
}

function checkPassword(password: string): Promise<boolean> {
  const params = { password }
  return new Promise((resolve, reject) => {
    axios.post<HiveApiV2Response<{ valid: boolean }>>("/core/users/check-password", params)
      .then(r => resolve(r.data.data.valid))
      .catch(err => reject(err))
  })
}

function postForgottenPassword(
  username: string,
  birth_date: string,
  recaptchaKey: string
): Promise<boolean> {
  const params = { username, birth_date, recaptcha: recaptchaKey }
  return new Promise((resolve, reject) => {
    axios.post<HiveApiV2Response>("/public/core/users/forgotten-password", params)
      .then(r => resolve(true))
      .catch(err => reject(err))
  })
}

function putPasswordByToken(password: string, userToken: string): Promise<boolean> {
  const params = {
    "X-PROFIL-TOKEN": userToken,
    password
  }
  return new Promise((resolve, reject) => {
    axios.put<HiveApiV2Response>("/token-security/core/users/password", params)
      .then(r => resolve(true))
      .catch(err => reject(err))
  })
}

function putPassword(password: string, previousPassword: string): Promise<User> {
  const params = { password, previous_password: previousPassword }
  return new Promise((resolve, reject) => {
    axios.put<HiveApiV2Response<{ user: User }>>("/core/users/password", params)
      .then(r => resolve(r.data.data.user))
      .catch(err => reject(err))
  })
}

function getUserToken(): Promise<User> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ user: User }>>("/core/users/user-token")
      .then(r => resolve(r.data.data.user))
      .catch(err => reject(err))
  })
}

const userQueries = {
  current,
  post,
  sendRegisterConfirmationMail,
  enableByToken,
  getCurrentByToken,
  emailExist,
  getForgottenEmail,
  putEmail,
  checkPassword,
  postForgottenPassword,
  putPasswordByToken,
  putPassword,
  getUserToken
}

export default userQueries