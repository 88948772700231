import axios from 'axios'
import { HiveApiV2Response } from '../../types'
import { Profil, ProfilUpdate } from './types'

function get(id: number): Promise<Profil> {
  return new Promise((resolve, reject) => {
    axios
      .get<HiveApiV2Response<{ profil: Profil }>>(`/core/profils/${id}`)
      .then((r) => resolve(r.data.data.profil))
      .catch((err) => reject(err))
  })
}

function put(params: ProfilUpdate) {
  params.firstname && (params.firstname = params.firstname?.trim())
  params.lastname && (params.lastname = params.lastname?.trim())
  params.maiden_name && (params.maiden_name = params.maiden_name?.trim())

  return new Promise((resolve, reject) => {
    axios
      .put(`/core/profils/current`, params)
      .then((r) => resolve(r))
      .catch((err) => reject(err))
  })
}

const profilQueries = {
  get,
  put,
}

export default profilQueries
