import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { User } from "../user/types"
import { Field } from "./types"
import MyDate from "../../../date/Date"


function getPublic(id: string): Promise<Field> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ field: Field }>>(`public/core/fields/${id}`)
      .then(r => resolve(r.data.data.field))
      .catch(err => reject(err))
  })
}

function getListPublic(user: User): Promise<Field[]> {

  const now = new MyDate()

  const options = { params: {
    'filters': {
      'field_option': {
        'public_application': 1,
        'recruitment_deadline_gte': now.format("Y-m-d")
      },
      'user_no_assignment': user.id
    }
  }}

  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ fields: Field[] }>>(`/core/fields`, options)
      .then(r => resolve(r.data.data.fields))
      .catch(err => reject(err))
  })
}


const queries = {
  getPublic,
  getListPublic
}

export default queries