import { User, UserUpdate } from "../../services/queries/core/user/types"
import { PayloadAction } from "@reduxjs/toolkit/dist/createAction"
import { UserType } from "./userSlice"

const setUser = (state: UserType, action: PayloadAction<User>) => {
  const user = action.payload
  state.user = user
}

const editUser = (state: UserType, action: PayloadAction<UserUpdate>) => {
  state.user = { ...state.user, ...action.payload } as User
}

const functions = {
  SET_USER: setUser,
  EDIT_USER: editUser
}

export default functions