import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'
import { UserType } from './userSlice'
import { AdminValidation } from '../../services/queries/recruitment/adminValidation/types'

const setAdminValidations = (state: UserType, action: PayloadAction<AdminValidation[]>) => {
  const adminValidations = action.payload
  state.adminValidations = adminValidations
}

const editAdminValidation = (state: UserType, action: PayloadAction<AdminValidation>) => {
  state.adminValidations = state.adminValidations?.map((av) =>
    av.admin_control?.field === action.payload.admin_control?.field &&
    av.admin_control?.file_type === action.payload.admin_control?.file_type
      ? action.payload
      : av
  )
}

const functions = {
  SET_ADMIN_VALIDATIONS: setAdminValidations,
  EDIT_ADMIN_VALIDATION: editAdminValidation,
}

export default functions
