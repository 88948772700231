import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { MonitoringFieldOption } from "./types"


export function getFromField(id: number): Promise<MonitoringFieldOption | null> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ field_option: MonitoringFieldOption | null }>>(`monitoring/fields/${id}/field-options`)
      .then(r => resolve(r.data.data.field_option))
      .catch(err => reject(err))
  })
}

const fieldOption = {
  getFromField
}

export default fieldOption