import { PayloadAction } from "@reduxjs/toolkit/dist/createAction"
import { UserType } from "./userSlice"
import { Profil, ProfilUpdate } from "../../services/queries/core/profil/types"

const setProfil = (state: UserType, action: PayloadAction<Profil>) => {
  const profil = action.payload
  state.profil = profil
}

const editProfil = (state: UserType, action: PayloadAction<ProfilUpdate>) => {
  state.profil = { ...state.profil, ...action.payload } as Profil
}

const functions = {
  SET_PROFIL: setProfil,
  EDIT_PROFIL: editProfil
}

export default functions