import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { Assignment } from "./types"


function getFromCurrentUser(params: any): Promise<Assignment[]> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ assignments: Assignment[] }>>(`core/assignments`, { params })
      .then(async (r) => {
        resolve(r.data.data.assignments)
      })
      .catch(err => reject(err))
  })
}

const assignment = {
  getFromCurrentUser
}

export default assignment