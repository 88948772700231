import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { Notification } from "./types"

function getFromCurrentUser(): Promise<Notification[]> {

  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ notifications: Notification[] }>>(`/core/notifications`)
      .then(r => resolve(r.data.data.notifications))
      .catch(err => reject(err))
  })

}

function read(ids: number[] = []): Promise<boolean> {

  const data = ids.length > 0 ? { 'filters': { 'ids': ids.join(";") } } : null
  return new Promise((resolve, reject) => {
    axios.put<HiveApiV2Response>(`/core/notifications/read`, data)
      .then(r => resolve(true))
      .catch(err => reject(err))
  })

}

function remove(ids: number[] = []): Promise<boolean> {

  const data = ids.length > 0 ? { 'filters': { 'ids': ids.join(";") } } : null
  return new Promise((resolve, reject) => {
    axios.delete<HiveApiV2Response>(`/core/notifications`, { data })
      .then(r => resolve(true))
      .catch(err => reject(err))
  })

}

const queries = {
  getFromCurrentUser,
  read,
  remove
}

export default queries