import axios, { AxiosResponse } from "axios"
import { TokenType } from "../../../token/types"


function get(username: string, password: string): Promise<AxiosResponse> {
  return axios.post("/oauth/token", {
    grant_type: "password",
    client_id: process.env.REACT_APP_API_CLIENT_KEY,
    client_secret: process.env.REACT_APP_API_SECRET_KEY,
    username: username,
    password: password
  })
}

function refresh(token: TokenType): Promise<AxiosResponse> {
  return axios.post("/oauth/token", {
    grant_type: "refresh_token",
    client_id: process.env.REACT_APP_API_CLIENT_KEY,
    client_secret: process.env.REACT_APP_API_SECRET_KEY,
    refresh_token: token.refresh_token
  })
}


const tokenQueries = {
  get,
  refresh
}

export default tokenQueries