import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { BriefForm } from "./types"


export function get(id: number): Promise<BriefForm> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ brief_form: BriefForm }>>(`recruitment/fields/${id}/brief-forms`)
      .then(r => resolve(r.data.data.brief_form))
      .catch(err => reject(err))
  })
}

const requests = {
  get
}

export default requests