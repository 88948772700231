import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { getInitialOrLocalToken } from "../../services/token"
import { TokenType } from "../../services/token/types"

export interface AuthType {
  login: string
  redirect: string
  token: TokenType|null
}

const initialState: AuthType = {
  login: "",
  redirect: "/",
  token: getInitialOrLocalToken()
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SET_LOGIN: (state, action: PayloadAction<string>) => {
      const login = action.payload
      state.login = login
    },
    SET_REDIRECT: (state, action: PayloadAction<string>) => {
      const redirect = action.payload
      state.redirect = redirect
    },
    LOG_IN: (state, action: PayloadAction<TokenType>) => {
      const token = action.payload
      state.token = token
    },
    LOG_OUT: state => {
      state.token = getInitialOrLocalToken()
    }
  }
})

export const { SET_LOGIN, SET_REDIRECT, LOG_IN, LOG_OUT } = authSlice.actions
export const selectLogin = (state: RootState) => state.auth.login
export const selectRedirect = (state: RootState) => state.auth.redirect
export const selectAccessToken = (state: RootState) => state.auth.token?.access_token

export default authSlice.reducer
