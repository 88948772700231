import React, {useState} from "react"
import {useAppSelector} from "../../store/hook"
import {
  selectFileUploadNotifications,
  selectFileUploaded,
  DELETE_NOTIFICATION,
} from "../../store/file-upload/fileUploadSlice"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import './UploadNotification.scss'
import { useDispatch } from "react-redux"
import 'bootstrap-icons/font/bootstrap-icons.css'

type Props = {}

const UploadNotification: React.FC<Props> = () => {
  const uploadList = useAppSelector(selectFileUploadNotifications)
  const uploadedFiles = useAppSelector(selectFileUploaded)
  const [showList, setShowList] = useState(true)
  const dispatch = useDispatch()

  const handleDeleteNotification = (uploadId: string, fileId: string) => {
    dispatch(DELETE_NOTIFICATION({
      uploadId: uploadId,
      fileId: fileId
    }))
  }

  const clearList = () => {
    dispatch(DELETE_NOTIFICATION(null))
  }

  return(
    <>
      {uploadList?.length > 0 &&
      <div className="toast-container position-fixed bottom-0 end-0 p-3">
        <div className="fade toast show" role="alert" aria-live="assertive" aria-atomic="true">
          <div className="toast-header custom-header">
            <strong className="me-auto">
              {uploadedFiles}/{uploadList.length} fichier(s) uploadé(s)
            </strong>
            <span
              className={showList ? "wrap-down" : "wrap-up"}
              onClick={() => setShowList(!showList)}
            >
              <FontAwesomeIcon
                icon={showList ? "chevron-down" : "chevron-up"}
              />
            </span>
            <FontAwesomeIcon
              icon="times"
              onClick={clearList}
            />
          </div>
          {showList &&
          <div className="toast-body custom-body">
            {uploadList.map(file =>
            <div className="fileItem" id={`un_${file.fileId}`} key={file.fileId}>
              <div className="fileContainer">
                <div className={`fileIcon state_${file.uploadState}`}>
                  <FontAwesomeIcon className="icon" icon="file" />
                </div>
                <div className="fileInfo">
                  <div className="fileName">
                    <strong>{file.fileName}</strong>
                  </div>
                  <div className="fileLabel">
                    {file.label}
                  </div>
                  <div className="fileSize">
                    {file.fileSize}
                    <div className={`state_${file.uploadState}_icon`}>
                      {file.uploadState === 'done'
                        ? <span className={`state_${file.uploadState}_icon`}>
                            <FontAwesomeIcon icon="check-circle" />
                          </span>
                        : (file.uploadState === 'error'
                            ? <span className={`state_${file.uploadState}_icon`}>
                                <FontAwesomeIcon icon="times-circle" />
                              </span>
                            : <span>{file.progress}%</span>)}
                    </div>
                  </div>
                  <div className="file-remove-notification">
                    <span onClick={() => handleDeleteNotification(file.uploadId, file.fileId)}>
                      <FontAwesomeIcon
                        icon="times"
                        size="xs"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="uploadProgressBarContainer">
                <div className="uploadProgressBar">
                  <div
                    className={`uploadProgressBarInner${file.uploadState === 'on_progress' ? '' : (file.uploadState === 'done' ? ' done' : ' error') }`}
                    style={{
                      width: `${file.progress}%`
                    }}
                  />
                </div>
              </div>
            </div>)}
          </div>}
        </div>
      </div>}
    </>
  )
}

export default UploadNotification