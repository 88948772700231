import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { File } from "./types"


function get(id: number): Promise<File> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ file: File }>>(`/core/files/${id}`)
      .then(r => resolve(r.data.data.file))
      .catch(err => reject(err))
  })
}

function getPublicProposalImage(id_field: number): Promise<File> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ file: File }>>(`/public/recruitment/fields/${id_field}/field-options/proposal-image`)
      .then(r => resolve(r.data.data.file))
      .catch(err => reject(err))
  })
}


const fileQueries = {
  get,
  getPublicProposalImage
}

export default fileQueries