import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { Confirmation, PostConfirmation } from "./types"


function post(id: number, c: PostConfirmation): Promise<Confirmation> {
  return new Promise((resolve, reject) => {
    axios.post<HiveApiV2Response<{ confirmation: Confirmation }>>(`recruitment/assignment-options/${id}/confirmations`, c)
      .then(r => resolve(r.data.data.confirmation))
      .catch(err => reject(err))
  })
}

const assignmentOption = {
  post
}

export default assignmentOption