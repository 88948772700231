import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { Folder } from "./types"

function getFromField(id: number): Promise<Folder | boolean> {
  const param = { params: {'filters' : { 
    'field': {
      'id' : id
    }
   }}}
  return new Promise((resolve, reject) => {
        axios.get<HiveApiV2Response<{ folders: Folder[] }>>(`/core/folders`,param)
      .then(r => resolve(r.data.data.folders.length > 0 ? r.data.data.folders[0] : false))
      .catch(err => reject(err))
  })
}

function getFromMultipleField(ids: number[]): Promise<Folder[]> {
  const params = { params: {'filters' : { 
    'fields': { 'ids' : ids.join(';') }
   }}}
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ folders: Folder[] }>>(`core/folders`,params)
      .then(r => resolve(r.data.data.folders))
      .catch(err => reject(err))
  })
}


const folder = {
  getFromField,
  getFromMultipleField,
}

export default folder