import core from "./core"
import monitoring from "./monitoring"
import recruitment from "./recruitment"
import missions from "./mission"

const queries = {
  core,
  recruitment,
  monitoring,
  missions
}

export default queries