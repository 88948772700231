import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { DistributionRule, PlanningState } from "./types"


function getFromField(id: number): Promise<DistributionRule[]> {
  const params = {
    "params": {
      'filters': {
        'counter': { 'field': { 'id': id } }
      }
    }
  }
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ distribution_rules: DistributionRule[] }>>(`monitoring/distribution-rules`, params)
      .then(r => resolve(r.data.data.distribution_rules))
      .catch(err => reject(err))
  })
}

function getState(id: number): Promise<PlanningState> {
  const params = {
    "params": {
      'functions': {
        'counter': {
          'counter_plannings': {
            'planning': {
              'lj_period': { 'day_equivalence': { 'type': 'group', 'name': 'day' } },
            }
          },
          'lj_user': { 'id': { 'type': 'count_distinct', 'name': 'nb_users' } }
        },
      },
      'filters': {
        'counter': { 'field': { 'id': id } }
      }
    }
  }
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ distribution_rules: { day: number, nb_users: string }[] }>>(`monitoring/distribution-rules`, params)
      .then(r => {

        let state: PlanningState = r.data.data.distribution_rules.length === 0 ? "EMPTY" : "COMPLETED"

        r.data.data.distribution_rules
          .forEach(dr => Number(dr.nb_users) === 0 && (state = "WAITING"))

        resolve(state)

      })
      .catch(err => reject(err))
  })
}

const distributionRule = {
  getFromField,
  getState
}

export default distributionRule