import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { Station } from "./types"


function getAllFromField(id: number): Promise<Station[]> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ stations: Station[] }>>(`recruitment/fields/${id}/stations`)
      .then(r => resolve(r.data.data.stations))
      .catch(err => reject(err))
  })
}

function getAllFromFieldPublic(id: number): Promise<Station[]> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ stations: Station[] }>>(`public/recruitment/fields/${id}/stations`)
      .then(r => resolve(r.data.data.stations))
      .catch(err => reject(err))
  })
}

function get(id: number): Promise<Station> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ station: Station }>>(`recruitment/stations/${id}`)
      .then(r => resolve(r.data.data.station))
      .catch(err => reject(err))
  })
}

const station = {
  getAllFromField, 
  getAllFromFieldPublic,
  get
}

export default station 