import { PublicWishPost } from './../../services/queries/recruitment/wish/types';
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store"

export interface WishType {
  wish: PublicWishPost
}

const initialState: WishType = {
  wish: {}
}

export const wishSlice = createSlice({
  name: "wish",
  initialState,
  reducers: {
    SET_WISH: (state, action: PayloadAction<PublicWishPost>) => {
      const wish = action.payload
      state.wish = wish
    }
  }
})

export const { SET_WISH } = wishSlice.actions
export const selectWish = (state: RootState) => state.wish.wish

export default wishSlice.reducer
