import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { Day } from "./types"


export function get(): Promise<any> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ days: Day[] }>>(`monitoring/days`)
      .then(r => resolve(r.data.data.days))
      .catch(err => reject(err))
  })
}

const day = {
  get
}

export default day