import axios from 'axios'
import { HiveApiV2Response } from '../../types'
import mime from 'mime'
import { UserFile, UserFileType } from './types'

function post(
  axiosConfig: Object,
  file: any,
  fileName: string,
  type: UserFileType,
  expiresAt?: string
): Promise<UserFile> {
  const data = new FormData()
  const fileMime = mime.getType(fileName)
  const fileContent = file.split(';base64,')
  data.append('file_content', fileContent[1])
  data.append('type', type)
  data.append('mimetype', fileMime ? fileMime : '')
  expiresAt && data.append('expiresAt', expiresAt)

  return new Promise((resolve, reject) => {
    axios
      .post<HiveApiV2Response<{ user_file: UserFile }>>('/core/user-files', data, axiosConfig)
      .then((res) => resolve(res.data.data.user_file))
      .catch((err) => reject(err))
  })
}

function get(): Promise<UserFile[]> {
  return new Promise((resolve, reject) => {
    axios
      .get<HiveApiV2Response<{ user_files: UserFile[] }>>(`/core/user-files`)
      .then((r) => {
        resolve(r.data.data.user_files)
      })
      .catch((err) => reject(err))
  })
}

function del(id: number): Promise<void> {
  return new Promise((resolve, reject) => {
    axios
      .delete<HiveApiV2Response<[]>>(`/core/user-files/${id}`)
      .then((r) => resolve())
      .catch((err) => reject(err))
  })
}

const userFileQueries = {
  post,
  get,
  del,
}

export default userFileQueries
