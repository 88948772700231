import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { VideoTraining } from "./types"


export function get(id: number): Promise<VideoTraining> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ video_training: VideoTraining }>>(`recruitment/video-trainings/${id}`)
      .then(r => resolve(r.data.data.video_training))
      .catch(err => reject(err))
  })
}

export function put(video: VideoTraining): Promise<VideoTraining> {
  return new Promise((resolve, reject) => {
    axios.put<HiveApiV2Response<{ video_training: VideoTraining }>>(`recruitment/video-trainings/${video.id}`,video)
      .then(r => resolve(r.data.data.video_training))
      .catch(err => reject(err))
  })
}

const videoTraining = {
  get,
  put
}

export default videoTraining