import axios from "axios"
import { User } from "../../core/user/types"
import { HiveApiV2Response } from "../../types"
import { CounterPlanning } from "./types"


export function getFromUser(id: number, user: User): Promise<CounterPlanning[]> {
  const param = user?.id ? {
    "params": {
      "filters": {
        "counter": {
          "user": { "id": user?.id }
        }
      }
    }
  } : {}
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ counter_plannings: CounterPlanning[] }>>(`monitoring/fields/${id}/counter-plannings`, param)
      .then(r => resolve(r.data.data.counter_plannings))
      .catch(err => reject(err))
  })
}

export function getFromCounters(id: number, idCounters: number[]): Promise<CounterPlanning[]> {
  const param = {
    "params": {
      "filters": {
        "counter": { "id": idCounters }
      }
    }
  }
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ counter_plannings: CounterPlanning[] }>>(`monitoring/fields/${id}/counter-plannings`, param)
      .then(r => resolve(r.data.data.counter_plannings))
      .catch(err => reject(err))
  })
}

const counterPlanning = {
  getFromUser,
  getFromCounters
}

export default counterPlanning