import mission from "./mission/mission"
import contract from "./contract/contract"
import expense from "./expense/expense"
import expenseReport from "./expenseReport/expenseReport"

const missions = {
  mission,
  contract,
  expense,
  expenseReport
}

export default missions