import { configureStore } from "@reduxjs/toolkit"
import fileUploadSlice from "./file-upload/fileUploadSlice"
import authSlice from "./auth/authSlice"
import userSlice from "./user/userSlice"
import wishSlice from "./wish/wishSlice"
import notificationSlice from "./notification/notificationSlice"

export function createStore(preloadedState = {}) {
  return configureStore({
    reducer: {
      auth: authSlice,
      user: userSlice,
      uploads: fileUploadSlice,
      wish: wishSlice,
      notification: notificationSlice
    },
    preloadedState: preloadedState
  })
}
const store = createStore()

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store