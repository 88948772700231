import axios from 'axios'
import { HiveApiV2Response } from '../../types'
import { Counter } from './types'
import { ConfirmationStates } from '../../../../views/UnauthenticatedApp/PlanningConfirmation/interface'

export function selfAssign(id: number): Promise<boolean> {
  return new Promise((resolve, reject) => {
    axios
      .put<HiveApiV2Response<{ counter_plannings: Counter[] }>>(`monitoring/counters/${id}/self-assign`)
      .then((r) => resolve(true))
      .catch((err) => reject(err))
  })
}

export function confirmReceipt(id: number, userToken: string, confirmationState: ConfirmationStates): Promise<boolean> {
  return new Promise((resolve, reject) => {
    axios
      .put<HiveApiV2Response>(`token-security/monitoring/counters/${id}`, {
        'X-PROFIL-TOKEN': userToken,
        confirmation_state: confirmationState,
      })
      .then((r) => resolve(true))
      .catch((err) => reject(err))
  })
}

const counterPlanning = {
  selfAssign,
  confirmReceipt,
}

export default counterPlanning
