import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { Wish, WishPost } from "./types"


export function post(id: number, wish: WishPost): Promise<Wish> {
  return new Promise((resolve, reject) => {
    axios.post<HiveApiV2Response<{ wish: Wish }>>(`recruitment/assignment-options/${id}/wishes`, wish)
      .then(r => resolve(r.data.data.wish))
      .catch(err => reject(err))
  })
}

const wish = {
  post
}

export default wish