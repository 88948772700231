import authCode from "./authCode/authCode"
import profil from "./profil/profil"
import profilQualification from "./profilQualification/profilQualification"
import token from "./token/token"
import user from "./user/user"
import userFile from "./userFile/userFile"
import file from "./file/file"
import field from "./field/field"
import assignment from "./assignment/assignment"
import folder from "./folder/folder"
import notification from "./notification/notification"

const coreQueries = {
  authCode,
  profil,
  profilQualification,
  token,
  user,
  userFile,
  file,
  field,
  assignment,
  folder,
  notification
}

export default coreQueries