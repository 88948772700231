import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Notification } from "../../services/queries/core/notification/types"
import { RootState } from "../store"

export interface NotificationType {
  notifications: Notification[]
  isOpen: boolean
}

const initialState: NotificationType = {
  notifications: [],
  isOpen: false
}

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    SET_NOTIFICATIONS: (state, action: PayloadAction<Notification[]>) => {
      const notifications = action.payload
      state.notifications = notifications
    },
    SET_IS_NOTIFICATION_OPEN: (state, action: PayloadAction<boolean>) => {
      const isOpen = action.payload
      state.isOpen = isOpen
    }
  }
})

export const { SET_NOTIFICATIONS, SET_IS_NOTIFICATION_OPEN } = notificationSlice.actions
export const selectNotifications = (state: RootState) => state.notification.notifications
export const selectIsNotificationOpen = (state: RootState) => state.notification.isOpen

export default notificationSlice.reducer
