import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux"
import "./index.scss";
import "./locales/i18n.ts";
import "./services/http";
import store from "./store/store"
import App from "./App";
import React from "react";

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Provider store={store}><App /></Provider>);