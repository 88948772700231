import fieldOption from "./fieldOption/fieldOption";
import counterPlanning from "./counterPlanning/counterPlanning";
import counter from "./counter/counter";
import distributionRule from "./distributionRule/distributionRule";
import day from "./day/day";
import station from "./station/station";

const monitoring = {
  fieldOption,
  counterPlanning,
  counter,
  distributionRule,
  day,
  station
}

export default monitoring