import React from "react";
import { useTranslation } from "react-i18next";
import "./PageLoader.scss";

type Props = {};

const PageLoader: React.FC<Props> = () => {

  const { t } = useTranslation()

  return (
    <div className="d-flex justify-content-center align-items-center h-100 wrap-page-loading">
      <div className="text-center">
        <span className="fs-6 mb-3 spinner-border text-primary" role="status"></span>
        <br />
        <label>{t("app.loading")}</label>
      </div>
    </div>
  )
};

export default PageLoader;
