import { Profil } from '../../services/queries/core/profil/types'
import { RootState } from '../store'
import userFn from './userFunctions'
import profilFn from './profilFunctions'
import profilQualificationFn from './profilQualificationFunctions'
import adminValidationFn from './adminValidationFunctions'
import filesFn from './filesFunctions'
import { createSlice } from '@reduxjs/toolkit'
import { User, UserRegister } from '../../services/queries/core/user/types'
import { UserFile, UserFileType } from '../../services/queries/core/userFile/types'
import { ProfilQualification } from '../../services/queries/core/profilQualification/types'
import { AdminValidation } from '../../services/queries/recruitment/adminValidation/types'

export interface UserType {
  user: User | UserRegister | null
  profil: Profil | null
  profilQualifications: Array<ProfilQualification>
  adminValidations: Array<AdminValidation>
  files: Array<UserFile>
}

const initialState: UserType = {
  user: null,
  profil: null,
  profilQualifications: [],
  adminValidations: [],
  files: [],
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: { ...userFn, ...profilFn, ...profilQualificationFn, ...adminValidationFn, ...filesFn },
})

export const {
  SET_USER,
  SET_PROFIL,
  SET_PROFIL_QUALIFICATIONS,
  SET_ADMIN_VALIDATIONS,
  EDIT_PROFIL,
  EDIT_PROFIL_QUALIFICATION,
  EDIT_ADMIN_VALIDATION,
  EDIT_USER,
  SET_FILES,
  ADD_FILE,
  REMOVE_FILE,
} = userSlice.actions
export const selectUser = (state: RootState) => state.user.user
export const selectProfil = (state: RootState) => state.user.profil
export const selectProfilQualifications = (state: RootState) => state.user.profilQualifications
export const selectAdminValidations = (state: RootState) => state.user.adminValidations
export const selectFilesByTypes = (state: RootState, types: Array<UserFileType>) =>
  state.user.files.filter((file) => types.some((type) => type === file.type))

export default userSlice.reducer
