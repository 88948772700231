import { PayloadAction } from "@reduxjs/toolkit/dist/createAction"
import { UserType } from "./userSlice"
import { UserFile } from "../../services/queries/core/userFile/types"

const setFiles = (state: UserType, action: PayloadAction<Array<UserFile>>) => {
  const files = action.payload
  state.files = files
}

const addFile = (state: UserType, action: PayloadAction<UserFile>) => {
  const file = action.payload
  state.files.push(file)
}

const removeFile = (state: UserType, action: PayloadAction<UserFile>) => {
  const file = action.payload
  state.files = state.files.filter(item => item.id !== file.id)
}

const functions = {
  SET_FILES: setFiles,
  ADD_FILE: addFile,
  REMOVE_FILE: removeFile
}

export default functions