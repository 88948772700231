import { setBearerToken } from "../http"
import api from "../queries"
import { TokenType } from "./types"

const TOKEN_STORAGE_KEY = "auth:token"


function addLocalToken(token: TokenType) {
  const tokenStr = JSON.stringify(token)
  localStorage.setItem(TOKEN_STORAGE_KEY, tokenStr)
  setBearerToken(token)
}

function getLocalToken() {
  let token = null
  const localTokenStr: string | null = localStorage.getItem(TOKEN_STORAGE_KEY)
  if (localTokenStr) {
    token = JSON.parse(localTokenStr)
  }
  return token
}

export function clearLocalToken() {
  window.localStorage.removeItem(TOKEN_STORAGE_KEY)
}

export function getInitialOrLocalToken(): TokenType {
  return getLocalToken() || {
    access_token: "",
    expires_in: 0,
    token_type: "bearer",
    scope: null,
    refresh_token: ""
  }
}

export function getLocalAccessToken(): string {
  const token = getInitialOrLocalToken()
  return token.access_token
}

export function getTokenFromApi(username: string, password: string): Promise<TokenType> {
  return new Promise((resolve, reject) => {
    api.core.token.get(username, password)
    .then(response => {
      const token = response.data        
      if (token) {
        addLocalToken(token)
        resolve(token)
      } else {
        reject()
      }
    })
    .catch(err => reject(err))
  })
}

export function refreshTokenFromApi(): Promise<TokenType> {
  return new Promise((resolve, reject) => {
    const token = getInitialOrLocalToken()
    api.core.token.refresh(token)
    .then(response => {
      const token = response.data
      if (token) {
        addLocalToken(token)
        resolve(token)
      } else {
        reject()
      }
    })
    .catch(() => reject())
  })
}