import assignmentOption from "./assignmentOption/assignmentOption"
import fieldOption from "./fieldOption/fieldOption"
import station from "./station/station"
import videoTraining from "./videoTraining/videoTraining"
import wish from "./wish/wish"
import planning from "./planning/planning"
import adminValidation from "./adminValidation/adminValidation"
import confirmation from "./confirmation/confirmation"
import briefForm from "./briefForm/briefForm"
import trainingForm from "./trainingForm/trainingForm"

const recruitment = {
  videoTraining,
  briefForm,
  trainingForm,
  fieldOption,
  assignmentOption,
  station,
  wish,
  planning,
  adminValidation,
  confirmation
}

export default recruitment