import axios from "axios"
import qs from "qs"

import { getLocalAccessToken, refreshTokenFromApi, clearLocalToken } from "../token"
import { TokenType } from "../token/types"

export const AUTH_API_URL = process.env.REACT_APP_API_URL

axios.defaults.baseURL = `${AUTH_API_URL}/api/v2`
axios.defaults.headers.common["Authorization"] = `Bearer ${getLocalAccessToken()}`
axios.defaults.headers.post["Content-Type"] = "application/json"
axios.defaults.paramsSerializer = params => {
  return qs.stringify(params)
}
axios.interceptors.response.use(
  response => response,
  error => {
    if (axios.isCancel(error)) {
      return Promise.reject(error.message)
    }

    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      return refreshTokenFromApi()
        .then(token => {
          setBearerToken(token)
          originalRequest.headers['Authorization'] = `Bearer ${token.access_token}`
          return axios(originalRequest)
        })
        .catch(() => {
          // Redirect to login if refresh token failed
          clearLocalToken()
          window.location.href = "/"
          return Promise.reject()
        })

    }

    return Promise.reject(error.response)
  })

export function setBearerToken(token: TokenType) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.access_token}`
}