import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { Planning } from "./types"


function getFromField(id: number): Promise<Planning[]> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ plannings: Planning[] }>>(`recruitment/fields/${id}/plannings`)
      .then(r => resolve(r.data.data.plannings))
      .catch(err => reject(err))
  })
}

function getFromFieldPublic(id: number): Promise<Planning[]> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ plannings: Planning[] }>>(`public/recruitment/fields/${id}/plannings`)
      .then(r => resolve(r.data.data.plannings))
      .catch(err => reject(err))
  })
}

const planning = {
  getFromField,
  getFromFieldPublic
}

export default planning