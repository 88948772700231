import axios from "axios"
import { HiveApiV2Response } from "../../types"
import { FieldOption } from "./types"


function getFromField(id: number): Promise<FieldOption> {
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ field_option: FieldOption }>>(`recruitment/fields/${id}/field-options`)
      .then(r => resolve(r.data.data.field_option))
      .catch(err => reject(err))
  })
}

function getFromMultipleField(ids: number[]): Promise<FieldOption[]> {
  const params = { params: {'filters' : { 
    'field': { 'ids' : ids.join(';') }
   }}}
  return new Promise((resolve, reject) => {
    axios.get<HiveApiV2Response<{ field_options: FieldOption[] }>>(`recruitment/field-options`,params)
      .then(r => resolve(r.data.data.field_options))
      .catch(err => reject(err))
  })
}

const fieldOption = {
  getFromField,
  getFromMultipleField
}

export default fieldOption 